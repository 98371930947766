var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.files,"item-key":"name","footer-props":{
      'items-per-page-text': _vm.$t('files.items-per-page'),
      'items-per-page-all-text': _vm.$t('files.items-per-page-all')
    },"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-checkbox',{staticClass:"mt-6",attrs:{"label":_vm.$t('files.thumbnail-show')},model:{value:(_vm.thumbnails.show),callback:function ($$v) {_vm.$set(_vm.thumbnails, "show", $$v)},expression:"thumbnails.show"}}),(_vm.thumbnails.show)?_c('v-slider',{staticClass:"mt-6 ml-8",attrs:{"min":"32","max":"128","step":"16","inverse-label":"true","label":((_vm.$t('files.thumbnail-size')) + " (" + (_vm.thumbnails.size) + ")")},model:{value:(_vm.thumbnails.size),callback:function ($$v) {_vm.$set(_vm.thumbnails, "size", $$v)},expression:"thumbnails.size"}}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.multipleDelete}},[_vm._v(_vm._s(_vm.$t('files.button:delete-selected')))]),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t('files.dialog:rename')))]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{attrs:{"label":_vm.$t('files.filename')},model:{value:(_vm.editedItem.newName),callback:function ($$v) {_vm.$set(_vm.editedItem, "newName", $$v)},expression:"editedItem.newName"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":_vm.closeRename}},[_vm._v(" "+_vm._s(_vm.$t('files.dialog:rename-cancel'))+" ")]),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.renameFileConfirm}},[_vm._v(" "+_vm._s(_vm.$t('files.dialog:rename-save'))+" ")])],1)],1)],1)],1)]},proxy:true},(_vm.thumbnails.show)?{key:"item.thumb",fn:function(ref){
    var item = ref.item;
return [_c('v-img',{attrs:{"src":("./files/" + (item.name) + "/thumbnail"),"max-height":_vm.thumbnails.size,"max-width":_vm.thumbnails.size,"contain":true}})]}}:null,{key:"item.lastModified",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.lastModified), 'long', _vm.$i18n.locale))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.open(item)}}},[_vm._v(" mdi-download ")]),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.fileRename(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.fileRemove(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" / "+_vm._s(items.itemsLength)+" ")]}}],null,true),model:{value:(_vm.selectedFiles),callback:function ($$v) {_vm.selectedFiles=$$v},expression:"selectedFiles"}})}
var staticRenderFns = []

export { render, staticRenderFns }